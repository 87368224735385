import { createGlobalStyle } from 'styled-components'
import { reset } from '@styles/partials/reset.style'
import { typography } from '@styles/partials/typography.style'
import { zIndex } from '@styles/vars/zIndex.style'
import { gatsbyTransitionLinkStyles } from '@styles/partials/gatsbyTransitionLink.style'
import { colors } from './vars/colors.style'

export const GlobalStyles = createGlobalStyle`
  ${reset}
  ${typography}
  ${gatsbyTransitionLinkStyles}

  :root {
    --reach-tabs: 1; // This disables the Reach UI warning about not including matching CSS files https://reach.tech/styling/
    --reach-dialog: 1;
  }

  * {
    -webkit-marquee-increment: 0vw; // This ensures that clamp values are updated on window resize in Safari
  }

  body {
    background-color: ${colors.offWhite};
    &.disable-scroll {
      overflow: hidden;
    }
  }

  #___gatsby,
  #gatsby-focus-wrapper {
    height: 100%;
  }

  [data-reach-dialog-overlay] {
    z-index: ${zIndex.modal};
  }

`
