import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PageTransitionLink from '@components/PageTransitionLink'
import { useStore } from '@Store'
import {
  OverlayNavNav,
  OverlayNavMain,
  OverlayMenu,
  OverlayMenuItem,
  OverlayNavEpisodes,
  OverlayNavButtons,
  OverlayNavEpisodeList,
  OverlayNavEpisode,
  OverlayNavEpisodeImageWrap,
  OverlayNavEpisodeImage,
  OverlayNavEpisodeButton,
} from './index.style'
import {
  Caption,
  CaptionSmall,
  CtaSmall,
  Heading5,
  TextBodySmall,
} from '@components/TextStyles'
import Container from '@components/Container'
import Spacer from '@components/Spacer'
import Grid from '@components/Grid'
import GridItem from '@components/GridItem'
import { GatsbyImage } from 'gatsby-plugin-image'
import { colors } from '@styles/vars/colors.style'
import Button from '@components/Button'
import IconArrowRight from '@svgs/IconArrowRight'
import IconArrowTopRight from '@svgs/IconArrowTopRight'
import PlayButton from '@components/PlayButton'
import AnimateSplitText from '@components/animation/AnimateSplitText'
import AnimateSlideIn from '@components/animation/AnimateSlideIn'
import AnimateImage from '@components/animation/AnimateImage'
import { animation } from '@styles/vars/animation.style'
import { localise } from '@utils/localise'

const OverlayNav = ({ pathname }) => {
  const { contentfulAbout, allContentfulEpisode } = useStaticQuery(graphql`
    query {
      contentfulAbout {
        slug
      }
      allContentfulEpisode(sort: { fields: episodeNumber }) {
        nodes {
          slug
          episodeNumber
          title
          podcastDuration
          intro {
            intro
          }
          episodeThumbnail {
            gatsbyImageData
            description
          }
        }
      }
    }
  `)
  const [store] = useStore()
  const { overlayNavIsOpen, showMenuMask } = store

  const [index, setIndex] = useState(0)
  const [offset, setOffset] = useState(3)

  const previous = () => {
    setIndex(index > 0 ? index - 1 : index)
  }

  const next = () => {
    setIndex(
      index < allContentfulEpisode.nodes.length - offset ? index + 1 : index
    )
  }

  useEffect(() => {
    setOffset(window.innerWidth >= 1440 ? 4 : 3)

    const updateOffset = () => {
      setOffset(window.innerWidth >= 1440 ? 4 : 3)
    }

    window.addEventListener('resize', updateOffset)

    return () => {
      window.removeEventListener('resize', updateOffset)
    }
  }, [])

  return (
    <OverlayNavMain open={overlayNavIsOpen} masked={showMenuMask}>
      <OverlayNavNav open={overlayNavIsOpen}>
        <OverlayMenu>
          <OverlayMenuItem>
            <CtaSmall>
              <PageTransitionLink to="/">{localise('Home')}</PageTransitionLink>
            </CtaSmall>
          </OverlayMenuItem>

          <OverlayMenuItem>
            <CtaSmall>
              <PageTransitionLink to={`/${contentfulAbout.slug}`}>
                {localise('About')}
              </PageTransitionLink>
            </CtaSmall>
          </OverlayMenuItem>

          <OverlayMenuItem>
            <CtaSmall>
              <PageTransitionLink to={`/${contentfulAbout.slug}?podcast`}>
                {localise('Podcast')}
              </PageTransitionLink>
            </CtaSmall>
          </OverlayMenuItem>

          <OverlayMenuItem>
            <CtaSmall>
              <a
                href={`https://www.laphilstore.com/exclusives/hollywood-bowl-exclusives/all-hollywood-bowl-exclusives.html`}
                target="_blank"
                rel="noreferrer"
              >
                {localise('The Bowl Store')}
                <IconArrowTopRight />
              </a>
            </CtaSmall>
          </OverlayMenuItem>

          <OverlayMenuItem>
            <CtaSmall>
              <a
                href={`https://www.hollywoodbowl.com/support/give-now`}
                target="_blank"
                rel="noreferrer"
              >
                {localise('Donate')}
                <IconArrowTopRight />
              </a>
            </CtaSmall>
          </OverlayMenuItem>
        </OverlayMenu>
      </OverlayNavNav>
      <OverlayNavEpisodes open={overlayNavIsOpen}>
        <OverlayNavButtons>
          <Container>
            <AnimateSlideIn animate={overlayNavIsOpen && !showMenuMask}>
              <Button
                label={localise('Previous')}
                iconRight={<IconArrowRight />}
                iconOnly={true}
                flip={true}
                color={colors.dark}
                onClick={previous}
                disabled={index === 0}
              ></Button>
            </AnimateSlideIn>
            <AnimateSlideIn
              animate={overlayNavIsOpen && !showMenuMask}
              delay={animation.wordDuration}
            >
              <Button
                label={localise('Next')}
                iconRight={<IconArrowRight />}
                iconOnly={true}
                color={colors.dark}
                onClick={next}
                disabled={index === allContentfulEpisode.nodes.length - offset}
              ></Button>
            </AnimateSlideIn>
          </Container>
        </OverlayNavButtons>
        <Container>
          <Heading5>
            <AnimateSplitText animate={overlayNavIsOpen && !showMenuMask}>
              {localise('Explore Episodes')}
            </AnimateSplitText>
          </Heading5>
          <Spacer size={25} />
          <OverlayNavEpisodeList>
            {React.Children.toArray(
              allContentfulEpisode.nodes.map((episode, episodeIndex) => {
                const intro =
                  episode.intro.intro.length > 85
                    ? episode.intro.intro.substring(0, 85) + `...`
                    : episode.intro.intro
                return (
                  <OverlayNavEpisode x={100 * (episodeIndex - index)}>
                    <PageTransitionLink to={`/${episode.slug}`}>
                      <Grid>
                        <GridItem mobile={5} tabletP={3} tabletL={2} desk={12}>
                          <OverlayNavEpisodeImageWrap>
                            <OverlayNavEpisodeImage
                              active={episodeIndex === index}
                              yScaleOffset={episodeIndex === index ? 0 : 0.1}
                            >
                              <AnimateImage
                                animate={overlayNavIsOpen && !showMenuMask}
                                parallax={1}
                                rootMargin={'0px -80px 0px 0px'}
                                fill={true}
                              >
                                <GatsbyImage
                                  image={
                                    episode.episodeThumbnail.gatsbyImageData
                                  }
                                  alt={episode.episodeThumbnail.description}
                                  objectPosition={`50% 50%`}
                                />
                              </AnimateImage>
                            </OverlayNavEpisodeImage>
                            <OverlayNavEpisodeButton
                              yOffset={episodeIndex === index ? 0 : 40}
                            >
                              <PlayButton label={localise('Play Episode')} />
                            </OverlayNavEpisodeButton>
                          </OverlayNavEpisodeImageWrap>
                        </GridItem>
                        <GridItem mobile={7} tabletP={9} tabletL={10} desk={12}>
                          <CaptionSmall>
                            <AnimateSlideIn
                              animate={overlayNavIsOpen && !showMenuMask}
                              rootMargin={'0px -80px 0px 0px'}
                            >
                              {localise('Episode')} {episode.episodeNumber}{' '}
                              <span>|</span> {episode.podcastDuration}
                            </AnimateSlideIn>
                          </CaptionSmall>
                          <Spacer size={8} />
                          <Caption color={colors.pink2}>
                            <AnimateSlideIn
                              animate={overlayNavIsOpen && !showMenuMask}
                              rootMargin={'0px -80px 0px 0px'}
                            >
                              {episode.title}
                            </AnimateSlideIn>
                          </Caption>
                          <Spacer size={18} />
                          <TextBodySmall>
                            <AnimateSplitText
                              animate={overlayNavIsOpen && !showMenuMask}
                              rootMargin={'0px -80px 0px 0px'}
                            >
                              {intro}
                            </AnimateSplitText>
                          </TextBodySmall>
                        </GridItem>
                      </Grid>
                    </PageTransitionLink>
                  </OverlayNavEpisode>
                )
              })
            )}
          </OverlayNavEpisodeList>
        </Container>
      </OverlayNavEpisodes>
    </OverlayNavMain>
  )
}

export default OverlayNav
