import React from 'react'

const IconArrowRight = ({
  width = 11,
  height = 11,
  fill = 'currentColor',
  responsive = false,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 11 11"
      aria-hidden="true"
      focusable="false"
      {...svgAttributes}
    >
      <path d="M2.33594 0V2L8.33594 2V8H10.3359V0L2.33594 0Z" fill={fill} />
      <path
        d="M7.9928 1L9.40701 2.41421L1.41418 10.4069L-2.89679e-05 8.99264L7.9928 1Z"
        fill={fill}
      />
    </svg>
  )
}

export default IconArrowRight
