import styled from 'styled-components'
import { animation } from '@styles/vars/animation.style'
import { easings } from '@styles/vars/easings.style'

export const AnimateSlideInMask = styled.span`
  display: block;
`

const BaseAnimateSlideInContent = styled.span`
  display: block;
  transition-property: transform, opacity;
  transition-timing-function: ${easings.out.cubic};
`

export const AnimateSlideInContent = styled(BaseAnimateSlideInContent).attrs(
  props => {
    return {
      style: {
        transitionDuration: `${props.duration}s`,
        transitionDelay: `${
          props.inView ? props.delay : animation.maskShowDuration
        }s`,
      },
    }
  }
)`
  opacity: ${props => (props.inView ? 1 : 0)};
  transform: ${({ direction, inView }) => {
    let offset

    switch (direction) {
      case 'top':
        offset = '0, -3rem'
        break

      case 'right':
        offset = '3rem, 0%'
        break

      case 'bottom':
        offset = '0%, 3rem'
        break

      case 'left':
        offset = '-3rem, 0%'
        break

      default:
        break
    }

    return `translate(${inView ? '0, 0' : offset})`
  }};
`
