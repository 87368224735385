import styled from 'styled-components'
import { zIndex } from '@styles/vars/zIndex.style'
import { easings } from '@styles/vars/easings.style'

export const MenuToggleWrapper = styled.div`
  line-height: 0;
  pointer-events: auto;
  position: relative;
  z-index: ${zIndex.mobileNavButtons};
`

export const MenuToggleMain = styled.button`
  position: relative;
  width: 4.4rem;
  height: 4.4rem;
  background-color: transparent;
  border: none;
`

export const MenuToggleBar = styled.div`
  position: absolute;
  top: calc(50% - 0.05rem);
  left: calc(50% - 1.8rem);
  width: 3.6rem;
  height: 0.2rem;
  background-color: ${props => props.fill};
  transition: background-color 0.2s ${easings.inOut.default};

  :first-child {
    transform: translateY(-0.4rem);
  }

  :last-child {
    transform: translateY(0.4rem);
  }
`
