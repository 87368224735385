import React from 'react'

const Play = ({
  width = 17,
  height = 20,
  fill = 'currentColor',
  responsive = true,
  open = true,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17 20"
      aria-hidden="true"
      focusable="false"
      {...svgAttributes}
    >
      <path
        d="M16.387 9.567a.5.5 0 0 1 0 .866L1.182 19.212a.5.5 0 0 1-.75-.433V1.222a.5.5 0 0 1 .75-.433l15.205 8.778Z"
        fill={fill}
      />
    </svg>
  )
}

export default Play
