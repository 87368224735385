import React from 'react'
import PropTypes from 'prop-types'
import { useInView } from 'react-intersection-observer'
import { AnimateSlideInContent, AnimateSlideInMask } from './index.style'
import { useStore } from '@Store/'
import { animation } from '@styles/vars/animation.style'

const AnimateSlideIn = ({
  direction = 'bottom',
  duration = 1.25,
  delay = 0,
  triggerOnce = true,
  animate = true,
  rootMargin,
  children,
}) => {
  const [ref, inView] = useInView({
    triggerOnce,
    rootMargin: rootMargin ? rootMargin : animation.rootMargin,
  })
  const [store] = useStore()
  const { showIntroMask, showPageMask } = store

  return (
    <AnimateSlideInMask ref={ref}>
      <AnimateSlideInContent
        inView={inView && animate && !showIntroMask && !showPageMask}
        direction={direction}
        duration={duration}
        delay={delay}
      >
        {children}
      </AnimateSlideInContent>
    </AnimateSlideInMask>
  )
}

AnimateSlideIn.propTypes = {
  children: PropTypes.node.isRequired,
  delay: PropTypes.number,
  duration: PropTypes.number,
  triggerOnce: PropTypes.bool,
  animate: PropTypes.bool,
  rootMargin: PropTypes.string,
  direction: PropTypes.oneOf(['top', 'right', 'left', 'bottom']),
}

export default AnimateSlideIn
