import React, { useReducer, createContext, useContext } from 'react'

export const StoreContext = createContext()
StoreContext.displayName = 'StoreContext'

const initialState = {
  smoothScroll: null,
  showIntroMask: true,
  showPageMask: false,
  showMenuMask: false,
  overlayNavIsOpen: false,
  fontIsLoaded: false,
  pathname: typeof window === 'undefined' ? null : window.location.pathname,
  popstate: 0,
  resized: 0,
  handleHeroImage: null,
  headerLight: true,
  webglIsActive: false,
}

function storeReducer(state, action) {
  switch (action.type) {
    case 'SET_SMOOTH_SCROLL': {
      return {
        ...state,
        smoothScroll: action.scroll,
      }
    }

    case 'UPDATE_PATHNAME': {
      return {
        ...state,
        pathname: action.pathname,
      }
    }

    case 'UPDATE_POP_STATE': {
      return {
        ...state,
        popstate: state.popstate + 1,
      }
    }

    case 'SHOW_INTRO_MASK': {
      return {
        ...state,
        showIntroMask: true,
      }
    }
    case 'HIDE_INTRO_MASK': {
      return {
        ...state,
        showIntroMask: false,
      }
    }

    case 'SHOW_PAGE_MASK': {
      return {
        ...state,
        showPageMask: true,
      }
    }
    case 'HIDE_PAGE_MASK': {
      return {
        ...state,
        showPageMask: false,
      }
    }

    case 'SHOW_MENU_MASK': {
      return {
        ...state,
        showMenuMask: true,
      }
    }
    case 'HIDE_MENU_MASK': {
      return {
        ...state,
        showMenuMask: false,
      }
    }

    case 'LOAD_FONTS': {
      return {
        ...state,
        fontIsLoaded: true,
      }
    }

    case 'SHOW_OVERLAY_NAV': {
      return {
        ...state,
        overlayNavIsOpen: true,
      }
    }
    case 'HIDE_OVERLAY_NAV': {
      return {
        ...state,
        overlayNavIsOpen: false,
      }
    }

    case 'UPDATE_RESIZED': {
      return {
        ...state,
        resized: state.resized + 1,
      }
    }

    case 'UPDATE_HANDLE_HERO_IMAGE': {
      return {
        ...state,
        handleHeroImage: action.handleImage,
      }
    }

    case 'SET_HEADER_LIGHT': {
      return {
        ...state,
        headerLight: true,
      }
    }
    case 'SET_HEADER_DARK': {
      return {
        ...state,
        headerLight: false,
      }
    }

    case 'START_WEBGL': {
      return {
        ...state,
        webglIsActive: true,
      }
    }

    default:
      throw new Error('Bad action type')
  }
}

export const updatePathname = (dispatch, pathname) =>
  dispatch({ type: 'UPDATE_PATHNAME', pathname })
export const updatePopState = dispatch => dispatch({ type: 'UPDATE_POP_STATE' })
export const setSmoothScroll = (dispatch, scroll) =>
  dispatch({ type: 'SET_SMOOTH_SCROLL', scroll })
export const showIntroMask = dispatch => dispatch({ type: 'SHOW_INTRO_MASK' })
export const hideIntroMask = dispatch => dispatch({ type: 'HIDE_INTRO_MASK' })
export const showPageMask = dispatch => dispatch({ type: 'SHOW_PAGE_MASK' })
export const hidePageMask = dispatch => dispatch({ type: 'HIDE_PAGE_MASK' })
export const showMenuMask = dispatch => dispatch({ type: 'SHOW_MENU_MASK' })
export const hideMenuMask = dispatch => dispatch({ type: 'HIDE_MENU_MASK' })
export const loadFonts = dispatch => dispatch({ type: 'LOAD_FONTS' })
export const showOverlayNav = dispatch => dispatch({ type: 'SHOW_OVERLAY_NAV' })
export const hideOverlayNav = dispatch => dispatch({ type: 'HIDE_OVERLAY_NAV' })
export const updateResized = dispatch => dispatch({ type: 'UPDATE_RESIZED' })
export const updateHandleHeroImage = (dispatch, handleImage) =>
  dispatch({ type: 'UPDATE_HANDLE_HERO_IMAGE', handleImage })
export const setHeaderLight = dispatch => dispatch({ type: 'SET_HEADER_LIGHT' })
export const setHeaderDark = dispatch => dispatch({ type: 'SET_HEADER_DARK' })
export const startWebgl = dispatch => dispatch({ type: 'START_WEBGL' })

function StoreProvider(props) {
  const [store, dispatch] = useReducer(storeReducer, initialState)
  const value = [store, dispatch]

  return <StoreContext.Provider value={value} {...props} />
}

export function useStore() {
  const context = useContext(StoreContext)

  if (!context) {
    throw new Error('useStore must be used within a StoreProvider')
  }

  return context
}

function Store(props) {
  return <StoreProvider>{props.children}</StoreProvider>
}

export default Store
