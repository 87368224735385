import styled from 'styled-components'
import { colors } from '@styles/vars/colors.style'
import { mq } from '@styles/vars/media-queries.style'
import { zIndex } from '@styles/vars/zIndex.style'
import { easings } from '@styles/vars/easings.style'

export const CookieBarMain = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  transform: ${({ show }) => (show ? 'translateY(0)' : 'translateY(100%)')};
  width: 100%;
  z-index: ${zIndex.cookieBar};
  transition: transform 0.2s ${easings.inOut.default};
`

export const CookieBarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.4rem;
  color: ${colors.light};
  background-color: ${colors.dark};
`

export const CookieBarText = styled.div`
  margin-right: 1rem;

  ${mq.mobileLMax} {
    flex: 1;
  }
`

export const CookieBarBtn = styled.button``
