import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import FontFaceObserver from 'fontfaceobserver'
import { debounce } from 'lodash'

// Components
import {
  useStore,
  updatePathname,
  updatePopState,
  updateResized,
  loadFonts,
} from '@Store'
import TransitionMask from '@components/PageTransitionMask'
import Gridlines from '@components/Gridlines'
import Header from '@components/Header'
import SmoothScroll from '@components/SmoothScroll'
import CookieBar from '@components/CookieBar'

// Fonts
import ApercuLAPhilEOTRegular from '../../fonts/ApercuLAPhil/ApercuLAPhil-Regular.eot'
import ApercuLAPhilWOFFRegular from '../../fonts/ApercuLAPhil/ApercuLAPhil-Regular.woff'
import ApercuLAPhilWOFF2Regular from '../../fonts/ApercuLAPhil/ApercuLAPhil-Regular.woff2'
import ApercuLAPhilEOTMedium from '../../fonts/ApercuLAPhil/ApercuLAPhil-Medium.eot'
import ApercuLAPhilWOFFMedium from '../../fonts/ApercuLAPhil/ApercuLAPhil-Medium.woff'
import ApercuLAPhilWOFF2Medium from '../../fonts/ApercuLAPhil/ApercuLAPhil-Medium.woff2'

// Styles
import { GlobalStyles } from '@styles/GlobalStyles.style'
import { font } from '@styles/vars/font.style'
import { colors } from '@styles/vars/colors.style'
import IntroMask from '@components/PageIntroMask'

const Layout = ({ children, location }) => {
  const [, dispatch] = useStore()

  const handleBrowserNavigationInteraction = useCallback(
    e => {
      updatePathname(dispatch, window.location.pathname)
      updatePopState(dispatch)
    },
    [dispatch]
  )

  const setViewportHeight = () => {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }

  const handleWebfontLoad = useCallback(() => {
    const fontA = new FontFaceObserver(font.primary.family)

    fontA.load().then(
      () => {
        loadFonts(dispatch)
      },
      () => {
        console.log('Font is not available')
      }
    )
  }, [dispatch])

  useEffect(() => {
    const $content = document.querySelector('.tl-edges')

    setViewportHeight()
    handleWebfontLoad()

    const resizeObserver = new ResizeObserver(
      debounce(() => {
        updateResized(dispatch)
      }, 50)
    )

    resizeObserver.observe($content)
    window.addEventListener('resize', setViewportHeight)
    window.addEventListener('popstate', handleBrowserNavigationInteraction)

    return () => {
      resizeObserver.unobserve($content)
      window.removeEventListener('resize', setViewportHeight)
      window.removeEventListener('popstate', handleBrowserNavigationInteraction)
    }
  }, [dispatch, handleWebfontLoad, handleBrowserNavigationInteraction])

  return (
    <>
      <GlobalStyles />
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="theme-color" content={colors.offWhite} />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#2b2172" />
        <meta name="msapplication-TileColor" content="#0e0f0e" />
        <meta name="theme-color" content="#ffffff"></meta>
        <link
          rel="stylesheet"
          href="https://use.typekit.net/lwd3bbg.css"
        ></link>
        <style>{`
          @font-face {
            font-family: 'Apercu LA Phil';
            src: url(${ApercuLAPhilEOTRegular}) format('eot'),
              url(${ApercuLAPhilWOFFRegular}) format('woff'),
              url(${ApercuLAPhilWOFF2Regular}) format('woff2');
            font-weight: ${font.secondary.weight.regular};
          }
          @font-face {
            font-family: 'Apercu LA Phil';
            src: url(${ApercuLAPhilEOTMedium}) format('eot'),
              url(${ApercuLAPhilWOFFMedium}) format('woff'),
              url(${ApercuLAPhilWOFF2Medium}) format('woff2');
            font-weight: ${font.secondary.weight.medium};
          }
        `}</style>
      </Helmet>

      <Gridlines show={false} />

      <SmoothScroll callbacks={location} />

      <TransitionMask />
      <IntroMask />

      <Header pathname={location.pathname} />

      <div id="smooth-wrapper">
        <div id="smooth-content">{children}</div>
      </div>

      <CookieBar />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
