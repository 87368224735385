export const animation = {
  maskShowDuration: 1,
  maskHideDuration: 1.6,
  lineDuration: 0.1,
  wordDuration: 0.05,
  charDuration: 0.04,
  imageStagger: 0.2,
  rootMargin: '0px 0px -30% 0px',
  negativeSpeeds: [
    0.96, 0.94, 0.98, 0.92, 0.96, 0.94, 0.98, 0.92, 0.96, 0.94, 0.98, 0.92,
    0.96, 0.94, 0.98, 0.92, 0.96, 0.94, 0.98, 0.92, 0.96, 0.94, 0.98, 0.92,
  ],
  mixedSpeeds: [
    0.98, 0.95, 1.01, 0.98, 1.03, 0.96, 1.02, 0.98, 0.95, 1.01, 0.98, 1.03,
    0.96, 1.02, 0.98, 0.95, 1.01, 0.98, 1.03, 0.96, 1.02, 0.98, 0.95, 1.01,
  ],
}
