import styled from 'styled-components'
import { animation } from '@styles/vars/animation.style'
import { easings } from '@styles/vars/easings.style'

export const FadeInWrapper = styled.div.attrs(props => ({
  style: {
    transitionDelay: `${
      props.inView ? props.delay : animation.maskShowDuration
    }s`,
  },
}))`
  opacity: ${props => (props.inView ? 1 : 0)};
  transition: opacity 0.6s ${easings.in.cubic};
`
