import styled from 'styled-components'
import { clamp } from '@styles/utils/conversion.style'
import { breakpoints } from '@styles/vars/breakpoints.style'
import { zIndex } from '@styles/vars/zIndex.style'
import { PageTransitionLinkMain } from '@components/PageTransitionLink/index.style'
import { mq } from '@styles/vars/media-queries.style'
import { easings } from '@styles/vars/easings.style'

export const HeaderPreLogo = styled.button`
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;
`

export const HeaderContainer = styled.header`
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${zIndex.header};

  ${mq.desk} {
    position: fixed;
  }
`

export const HeaderMain = styled.div`
  position: relative;
  z-index: ${zIndex.headerButtons};
`

export const HeaderInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${clamp('padding-bottom', 10, 37, breakpoints.mobile, breakpoints.contained)}
  ${clamp('padding-top', 10, 37, breakpoints.mobile, breakpoints.contained)}
`

export const HeaderLogo = styled.div`
  pointer-events: auto;
  position: relative;
  z-index: ${zIndex.mobileNavButtons};

  ${PageTransitionLinkMain} {
    display: block;
    ${clamp('height', 13, 19)};
    ${clamp('width', 200, 284)};
  }

  svg path {
    transition: fill 0.3s ${easings.inOut.default};
  }
`
