import styled from 'styled-components'
import { font } from '@styles/vars/font.style'
import { clamp } from '@styles/utils/conversion.style'

export const textStylesConfig = {
  heading1: {
    family: font.primary.family,
    weight: font.primary.weight.regular,
    size: {
      min: 54,
      max: 140,
    },
    lineHeight: 0.9,
    letterSpacing: 0.03,
    uppercase: true,
  },
  heading2: {
    family: font.primary.family,
    weight: font.primary.weight.regular,
    size: {
      min: 42,
      max: 86,
    },
    lineHeight: 0.95,
    letterSpacing: 0.03,
    uppercase: true,
  },
  heading3: {
    family: font.primary.family,
    weight: font.primary.weight.regular,
    size: {
      min: 36,
      max: 60,
    },
    lineHeight: 1,
    letterSpacing: 0.03,
    uppercase: true,
  },
  heading4: {
    family: font.primary.family,
    weight: font.primary.weight.regular,
    size: {
      min: 28,
      max: 32,
    },
    lineHeight: 1.2,
    letterSpacing: 0.03,
    uppercase: true,
  },
  heading5: {
    family: font.primary.family,
    weight: font.primary.weight.medium,
    size: {
      min: 20,
      max: 24,
    },
    lineHeight: 1.3,
    letterSpacing: 0.03,
    uppercase: true,
  },
  body: {
    family: font.secondary.family,
    weight: font.secondary.weight.regular,
    size: {
      min: 20,
      max: 24,
    },
    lineHeight: 1.4,
    letterSpacing: 0,
  },
  bodySmall: {
    family: font.secondary.family,
    weight: font.secondary.weight.regular,
    size: {
      min: 14,
      max: 16,
    },
    lineHeight: 1.4,
    letterSpacing: 0,
  },
  caption: {
    family: font.secondary.family,
    weight: font.secondary.weight.medium,
    uppercase: true,
    size: {
      min: 16,
      max: 18,
    },
    lineHeight: 1,
    letterSpacing: -0.01,
  },
  captionSmall: {
    family: font.secondary.family,
    weight: font.secondary.weight.medium,
    uppercase: true,
    size: {
      min: 12,
      max: 13,
    },
    lineHeight: 1,
    letterSpacing: -0.01,
  },
  cta: {
    family: font.secondary.family,
    weight: font.secondary.weight.medium,
    size: {
      min: 18,
      max: 18,
    },
    lineHeight: 1,
    letterSpacing: -0.01,
    uppercase: true,
  },
  ctaSmall: {
    family: font.secondary.family,
    weight: font.secondary.weight.medium,
    size: {
      min: 16,
      max: 18,
    },
    lineHeight: 1,
    letterSpacing: -0.01,
    uppercase: true,
  },
}

export const getStyles = style => `
  max-width: ${style.maxWidth ? `${style.maxWidth}em` : ''};
  ${clamp('font-size', style.size.min, style.size.max)}
  font-family: ${style.family};
  font-weight: ${style.weight};
  line-height: ${style.lineHeight};
  ${style.letterSpacing ? `letter-spacing: ${style.letterSpacing}em` : ''};
  ${style.uppercase ? 'text-transform: uppercase' : ''};
  color: ${style.color ? style.color : ''};
`

export const Heading1 = styled.p`
  ${props =>
    getStyles({
      ...textStylesConfig.heading1,
      maxWidth: props.maxWidth,
      color: props.color,
    })}

  i {
    font-style: normal;
    letter-spacing: -0.07em;
  }
`

export const Heading2 = styled.p`
  ${props =>
    getStyles({
      ...textStylesConfig.heading2,
      maxWidth: props.maxWidth,
      color: props.color,
    })}
`

export const Heading3 = styled.p`
  ${props =>
    getStyles({
      ...textStylesConfig.heading3,
      maxWidth: props.maxWidth,
      color: props.color,
    })}
`
export const Heading4 = styled.p`
  ${props =>
    getStyles({
      ...textStylesConfig.heading4,
      maxWidth: props.maxWidth,
      color: props.color,
    })}
`
export const Heading5 = styled.p`
  ${props =>
    getStyles({
      ...textStylesConfig.heading5,
      maxWidth: props.maxWidth,
      color: props.color,
    })}
`

export const TextBody = styled.p`
  ${props =>
    getStyles({
      ...textStylesConfig.body,
      maxWidth: props.maxWidth,
      color: props.color,
    })}
`

export const TextBodySmall = styled.p`
  ${props =>
    getStyles({
      ...textStylesConfig.bodySmall,
      maxWidth: props.maxWidth,
      color: props.color,
    })}
`

export const Caption = styled.p`
  ${props =>
    getStyles({
      ...textStylesConfig.caption,
      maxWidth: props.maxWidth,
      color: props.color,
    })}
`

export const CaptionSmall = styled.p`
  ${props =>
    getStyles({
      ...textStylesConfig.captionSmall,
      maxWidth: props.maxWidth,
      color: props.color,
    })}
`

export const Cta = styled.p`
  ${props =>
    getStyles({
      ...textStylesConfig.cta,
      maxWidth: props.maxWidth,
      color: props.color,
    })}
`

export const CtaSmall = styled.p`
  ${props =>
    getStyles({
      ...textStylesConfig.ctaSmall,
      maxWidth: props.maxWidth,
      color: props.color,
    })}
`
