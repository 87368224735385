import styled from 'styled-components'
import { clamp } from '@styles/utils/conversion.style'
import { breakpoints } from '@styles/vars/breakpoints.style'

export const paddingMin = 24
export const paddingMax = 48
export const containerMaxWidth = paddingMax * 2 + breakpoints.contained

export const matchContainerPadding = (property, invert = false) => {
  const min = invert ? -paddingMin : paddingMin
  const max = invert ? -paddingMax : paddingMax

  return `
    ${clamp(property, min, max, breakpoints.mobile, breakpoints.tabletL)}
  `
}

export const ContainerMain = styled.div`
  width: 100%;
  max-width: ${props =>
    props.fullWidth ? undefined : `${containerMaxWidth / 10}rem`};
  margin: 0 auto;
  ${clamp(
    'padding-left',
    paddingMin,
    paddingMax,
    breakpoints.mobile,
    breakpoints.tabletL
  )}
  ${clamp(
    'padding-right',
    paddingMin,
    paddingMax,
    breakpoints.mobile,
    breakpoints.tabletL
  )}
  overflow: ${props => (props.allowOverflow ? undefined : 'hidden')};
`
