import React, { useRef, useEffect } from 'react'
import gsap from 'gsap'
import { hideOverlayNav, hidePageMask, hideMenuMask, useStore } from '@Store'
import { TransitionMaskWrapper, TransitionMaskBlock } from './index.style'
import { animation } from '@styles/vars/animation.style'

const TransitionMask = () => {
  const [store, dispatch] = useStore()
  const { smoothScroll } = store
  const maskRef = useRef()
  const smoothScrollRef = useRef()

  useEffect(() => {
    smoothScrollRef.current = smoothScroll
  }, [smoothScroll])

  useEffect(() => {
    const tl = gsap.timeline()

    if (store.showPageMask) {
      const mask = maskRef.current

      tl.set(mask, { transformOrigin: '50% 100%' })
        .to(mask, {
          scaleY: 1,
          duration: animation.maskShowDuration,
          ease: 'power3.inOut',
          onStart: () => {
            hideOverlayNav(dispatch)
          },
          onComplete: () => {
            if (smoothScrollRef.current) {
              smoothScrollRef.current.scrollTop(0)
            }
          },
        })
        .set(mask, { transformOrigin: '50% 0%' })
        .to(mask, {
          scaleY: 0,
          delay: 0.5,
          duration: animation.maskHideDuration,
          ease: 'power3.inOut',
        })
        .add(() => hidePageMask(dispatch), '-=.8')
    }

    if (store.showMenuMask) {
      const mask = maskRef.current

      tl.set(mask, { transformOrigin: '50% 100%' })
        .to(mask, {
          scaleY: 1,
          duration: animation.maskShowDuration,
          ease: 'power3.inOut',
        })
        .set(mask, { transformOrigin: '50% 0%' })
        .to(mask, {
          scaleY: 0,
          duration: animation.maskHideDuration,
          ease: 'power3.inOut',
        })
        .add(() => hideMenuMask(dispatch), '-=.8')
    }

    // return () => {
    //   if (tl) {
    //     tl.kill()
    //   }
    // }
  }, [store.showPageMask, store.showMenuMask, dispatch])

  return (
    <TransitionMaskWrapper show={store.showPageMask}>
      <TransitionMaskBlock ref={maskRef} />
    </TransitionMaskWrapper>
  )
}

export default TransitionMask
