import gsap from 'gsap'

export function disablePageScroll() {
  document.body.classList.add('disable-scroll')
}

export function enablePageScroll() {
  document.body.classList.remove('disable-scroll')
}

export function focusElement(el) {
  el.setAttribute('tabindex', 0)
  el.focus()
}

export function scrollToPoint(smoothScroll, target, options = {}) {
  gsap.to(smoothScroll, {
    scrollTop: isNaN(target) ? smoothScroll.offset(target) : target,
    duration: 0.6,
    ease: 'power2.inOut',
    ...options,
  })
}
