import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import gsap from 'gsap'
import { hideOverlayNav, showOverlayNav, showMenuMask, useStore } from '@Store'
import { disablePageScroll, enablePageScroll } from '@utils'
import { MenuToggleMain, MenuToggleBar, MenuToggleWrapper } from './index.style'
import { localise } from '@utils/localise'
import { scrollToPoint } from '@utils'

const MenuToggle = ({ fill }) => {
  const [store, dispatch] = useStore()
  const { overlayNavIsOpen, smoothScroll } = store
  const toggleRef = useRef()
  const barRefA = useRef()
  const barRefB = useRef()

  useEffect(() => {
    const tlOpen = gsap.timeline({ paused: true })
    const tlClose = gsap.timeline({ paused: true })

    tlOpen
      .to(barRefA.current, {
        y: 0,
        rotate: 45,
        duration: 0.2,
        ease: 'power2.inOut',
      })
      .to(
        barRefB.current,
        {
          y: 0,
          rotate: -45,
          duration: 0.2,
          ease: 'power2.inOut',
        },
        0
      )

    tlClose
      .to(barRefA.current, {
        y: -4,
        rotate: 0,
        duration: 0.2,
        ease: 'power2.inOut',
      })
      .to(
        barRefB.current,
        {
          y: 4,
          rotate: 0,
          duration: 0.2,
          ease: 'power2.inOut',
        },
        0
      )

    if (overlayNavIsOpen) {
      disablePageScroll()
      if (window.innerWidth < 1240) scrollToPoint(smoothScroll, 0)
      tlOpen.play()
    } else {
      enablePageScroll()
      tlClose.play()
    }

    return () => {
      tlOpen.kill()
      tlClose.kill()
    }
  }, [overlayNavIsOpen])

  const handleClick = () => {
    if (store.showMenuMask) return

    showMenuMask(dispatch)

    if (overlayNavIsOpen) {
      hideOverlayNav(dispatch)
    } else {
      showOverlayNav(dispatch)
    }
  }

  return (
    <MenuToggleWrapper ref={toggleRef}>
      <MenuToggleMain
        onClick={handleClick}
        aria-label={localise('Toggle Menu')}
      >
        <MenuToggleBar ref={barRefA} fill={fill} />
        <MenuToggleBar ref={barRefB} fill={fill} />
      </MenuToggleMain>
    </MenuToggleWrapper>
  )
}

MenuToggle.propTypes = {
  fill: PropTypes.string,
}

export default MenuToggle
