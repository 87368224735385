import React from 'react'
import PropTypes from 'prop-types'
import {
  ButtonContent,
  ButtonMain,
  ButtonIcon,
  ButtonText,
} from './index.style'
import PageTransitionLink from '@components/PageTransitionLink'
import IconArrowRight from '@svgs/IconArrowRight'

/**
 *
 * @prop type
 * Defines the markup and functionality
 * @prop variant
 * Defines the visual style
 * @prop href
 * If defined this renders the component as an anchor element with an href attribute <a href={href} />
 * @prop to
 * If type is internalLink this is the url used in the PageTransitionLink component
 * @prop label
 * If defined this adds an aria-label to the component. Necessary for icon only buttons.
 */

const Button = ({
  type,
  variant,
  theme,
  disabled,
  href,
  to,
  label,
  iconLeft,
  iconRight,
  iconOnly,
  flip,
  onClick,
  children,
  ...props
}) => {
  const content = (
    <ButtonContent>
      {iconLeft && <ButtonIcon left>{iconLeft}</ButtonIcon>}
      {children && <ButtonText>{children}</ButtonText>}
      {iconRight && iconOnly ? (
        <>
          <ButtonIcon right>{iconRight}</ButtonIcon>
          <ButtonIcon right>{iconRight}</ButtonIcon>
        </>
      ) : iconRight ? (
        <ButtonIcon right>{iconRight}</ButtonIcon>
      ) : (
        <ButtonIcon hoverShift={true} right>
          <IconArrowRight />
        </ButtonIcon>
      )}
    </ButtonContent>
  )

  return (
    <>
      {type === 'button' && (
        <ButtonMain
          as="button"
          variant={variant}
          theme={theme}
          disabled={disabled}
          aria-label={label}
          onClick={onClick}
          iconOnly={iconOnly}
          flip={flip}
          hoverShift={!iconOnly && !iconRight}
        >
          {content}
        </ButtonMain>
      )}

      {type === 'externalLink' && (
        <ButtonMain
          as="a"
          href={href}
          variant={variant}
          theme={theme}
          aria-label={label}
          onClick={onClick}
          iconOnly={iconOnly}
          flip={flip}
          hoverShift={!iconOnly && !iconRight}
          {...props}
        >
          {content}
        </ButtonMain>
      )}

      {type === 'internalLink' && (
        <PageTransitionLink to={to} onClick={onClick}>
          <ButtonMain
            as="div"
            variant={variant}
            theme={theme}
            disabled={disabled}
            aria-label={label}
            iconOnly={iconOnly}
            flip={flip}
            hoverShift={!iconOnly && !iconRight}
          >
            {content}
          </ButtonMain>
        </PageTransitionLink>
      )}
    </>
  )
}

Button.propTypes = {
  type: PropTypes.oneOf(['button', 'internalLink', 'externalLink']).isRequired,
  variant: PropTypes.oneOf(['primary', 'secondary']),
  theme: PropTypes.oneOf(['solid', 'outline']),
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
  href: function (props, propName) {
    if (
      props['type'] === 'externalLink' &&
      (props[propName] === undefined || typeof props[propName] !== 'string')
    ) {
      return new Error('Please provide a href prop!')
    }
  },
  to: function (props, propName) {
    if (
      props['type'] === 'internalLink' &&
      (props[propName] === undefined || typeof props[propName] !== 'string')
    ) {
      return new Error('Please provide a to prop!')
    }
  },
  label: PropTypes.string,
  iconLeft: PropTypes.node,
  iconRight: PropTypes.node,
  iconOnly: PropTypes.bool,
  flip: PropTypes.bool,
  onClick: function (props, propName) {
    if (
      props['type'] === 'button' &&
      (props[propName] === undefined || typeof props[propName] !== 'function')
    ) {
      return new Error('Please provide an onClick function!')
    }
  },
  children: PropTypes.node,
}

Button.defaultProps = {
  type: 'button',
  variant: 'primary',
  theme: 'solid',
}

export default Button
