const stringRef = {
  'The First': 'LOS PRIMEROS',
  Years: 'AÑOS',
  'Presented by': 'Presentado por',
  'An audio podcast and walking tour through Bowl history–from Highland Avenue to the back benches, from 1922 to today.':
    'Un podcast y paseo virtual a través de la historia del Bown, desde Highland Avenue hasta los bancos traseros, desde 1922 hasta la actualidad',
  'Take the Tour': 'IR AL TOUR',
  Previous: 'Anterior',
  Next: 'Siguiente',
  Other: 'Otro',
  Episode: 'Episodio',
  'Explore Episodes': 'Explorar episodios',
  'Play Episode': 'Reproducir episodio',
  'Pause Episode': 'Pausar episodio',
  'Listen to episode': 'ESCUCHAR EPISODIO',
  'Read transcription': 'LEER TRANSCRIPCIÓN',
  'View map location': 'VER MAPA',
  'Listen to podcast': 'ESCUCHAR PODCAST',
  'View all episodes': 'VER TODOS LOS EPISODIOS',
  'Photo Credit': 'CRÉDITOS',
  '©2022 LA Phil. All rights reserved. Use of this site constitutes acceptance of our user agreement and privacy policy and cookie statement.':
    '©2022 LA Phil. Todos los derechos reservados. El uso de este sitio implica la aceptación de nuestro acuerdo de usuario, política de privacidad y declaración de cookies.',
  'A podcast and walking tour through Bowl history–from Highland Avenue to the back benches, from 1922 to today.':
    'Un podcast y paseo virtual a través de la historia del Bown, desde Highland Avenue hasta los bancos traseros, desde 1922 hasta la actualidad',
  Home: 'HOME',
  About: 'NOSOTROS',
  'The Bowl Store': 'THE BOWL TIENDA',
  Donate: 'DONAR',
  Calendar: 'Calendario',
  Podcast: 'PODCAST',
  'Presented by Kaiser Permanente: Official partner in health & harmony':
    'Presentado por Kaiser Permanente: Socio Oficial en salud y armonía',
  'Back to top': 'Volver al principio',
  'Website by': 'Página Web por:',
  'Toggle Menu': 'Menú desplegale',
}

function localise(string) {
  let esUS = stringRef[string]

  if (esUS === undefined) {
    console.log('--------------------------------------------------')
    console.log(string + ' not yet added in localisation object.')
    console.log('--------------------------------------------------')
  }

  if (process.env.GATSBY_CONTENTFUL_LOCALE === 'en-US') return string
  else if (process.env.GATSBY_CONTENTFUL_LOCALE === 'es-US')
    return esUS ? esUS : string
  else return string
}

export { localise }
