import styled from 'styled-components'
import { setVh } from '@styles/utils/conversion.style'
import { colors } from '@styles/vars/colors.style'
import { clamp } from '@styles/utils/conversion.style'
import { easings } from '@styles/vars/easings.style'
import { zIndex } from '@styles/vars/zIndex.style'
import { PageTransitionLinkMain } from '@components/PageTransitionLink/index.style'
import { mq } from '@styles/vars/media-queries.style'
import { breakpoints } from '@styles/vars/breakpoints.style'
import { Heading5 } from '@styles/vars/textStyles.style'
import { ButtonMain } from '@components/Button/index.style'
import { animation } from '@styles/vars/animation.style'
import Light from './images/nav-light.png'
import { ContainerMain } from '@components/Container/index.style'

export const OverlayNavMain = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  ${setVh('height', 100)}
  z-index: ${zIndex.overlayNav};
  pointer-events: ${props => (props.open && !props.masked ? 'auto' : 'none')};
  visibility: ${props => (props.open ? `visible` : `hidden`)};
  transition: visibility 0s ${animation.maskShowDuration}s;

  &:before {
    background-color: ${colors.purple3};
    border-bottom: 0.1rem solid ${colors.darkblue};
    content: '';
    ${clamp('height', 64, 118)};
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: ${zIndex.overlayNav};

    ${mq.desk} {
      z-index: 2;
    }
  }
`

export const OverlayNavNav = styled.nav`
  background-color: ${colors.purple3};
  left: 0;
  position: absolute;
  right: 0;
  ${clamp('top', 64, 118)};
  z-index: 3;

  ${mq.desk} {
    background-color: transparent;
    left: 50%;
    right: auto;
    top: ;
    ${clamp('top', 48, 59, breakpoints.desk, breakpoints.contained)};
    transform: translateX(-50%) translateY(-50%);
  }
`

export const OverlayMenu = styled.ul`
  display: flex;
  list-style: none;
  overflow: scroll;
  ${clamp('padding-left', 24, 48)};
  ${clamp('padding-right', 24, 48)};
  padding-bottom: 2.5rem;
  padding-top: 2.5rem;
  white-space: nowrap;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */

  ${mq.desk} {
    border-top: none;
    overflow: hidden;
    padding: 0;
  }

  ::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }
`

export const OverlayMenuItem = styled.li`
  padding-right: 2.4rem;

  &:last-child {
    padding-right: 0;
  }

  a {
    color: ${colors.offWhite};
    display: inline-block;
    position: relative;
    text-decoration: none;

    &:before {
      background-color: ${colors.light};
      bottom: 0;
      content: '';
      height: 0.1rem;
      left: 0;
      position: absolute;
      right: 0;
      transform: scaleX(0);
      transform-origin: 0% 0%;
      transition: transform 0.5s ${easings.inOut.default};
    }

    &:hover {
      &:before {
        transform: scaleX(1);
      }
    }
  }

  svg {
    display: inline-block;
    ${clamp('margin-left', 4, 6)};
    position: relative;
  }
`

export const OverlayNavEpisodes = styled.div`
  background: ${colors.darkblue};
  color: ${colors.offWhite};
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  ${clamp('padding-top', 130, 164, breakpoints.mobile, breakpoints.desk)};
  padding-right: 5rem;
  position: relative;
  width: calc(100% + 5rem);
  z-index: 1;

  ${mq.desk} {
    background-color: ${colors.purple3};
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 0;
  }

  ${Heading5} {
    padding-top: 4.3rem;

    ${mq.desk} {
      padding-top: 0;
    }
  }
`

export const OverlayNavButtons = styled.div`
  display: none;
  position: relative;
  text-align: right;
  transform: translateY(80%);
  z-index: 2;

  ${mq.desk} {
    display: block;
  }

  ${ContainerMain} {
    display: flex;
    justify-content: flex-end;
  }

  ${ButtonMain}:last-child {
    margin-left: 1.2rem;
  }
`

export const OverlayNavEpisodeList = styled.ul`
  list-style-type: none;
  padding-bottom: 6rem;
  position: relative;

  ${mq.desk} {
    padding-bottom: 0;
    width: 32.5%;
  }

  ${mq.deskM} {
    width: 24.5%;
  }

  ${mq.contained} {
    width: 22.5%;
  }
`

export const OverlayNavEpisode = styled.li`
  padding-bottom: 2.4rem;

  ${PageTransitionLinkMain} {
    display: block;
    width: 100%;
  }

  ${mq.desk} {
    padding-bottom: 0;
    padding-right: 4.8rem;
    transform: translateX(${props => props.x}%);
    transition: transform 0.8s ${easings.out.cubic};

    &:nth-child(1n + 2) {
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
`

export const OverlayNavEpisodeImage = styled.div`
  border-radius: 1rem;
  overflow: hidden;
  padding-bottom: 100%;
  position: relative;

  ${mq.desk} {
    height: 39vh;
    max-height: 47rem;
    padding-bottom: 0;
    transform: scaleY(${props => 1 - props.yScaleOffset});
    transform-origin: 50% 100%;
    transition: transform 0.7s ${easings.out.cubic};
    will-change: transform;
  }

  .gatsby-image-wrapper {
    ${mq.deskMax} {
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    ${mq.desk} {
      height: 100%;
      transform: scaleY(${props => 1 + props.yScaleOffset});
      transform-origin: 50% 50%;
      transition: transform 0.7s ${easings.out.cubic};
    }

    &:after {
      ${mq.desk} {
        background: ${colors.dark};
        bottom: 0;
        content: '';
        left: 0;
        opacity: ${props => (props.active ? 0 : 0.4)};
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;
        transition: opacity 0.7s ${easings.out.cubic};
        z-index: 2;
        will-change: opacity;
      }
    }
  }
`

export const OverlayNavEpisodeButton = styled.div`
  display: none;
  left: 50%;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%)
    translateY(${props => props.yOffset}%);
  transition: opacity 0.7s ${easings.out.cubic};

  ${mq.desk} {
    display: block;
  }
`

export const OverlayNavEpisodeImageWrap = styled.div`
  position: relative;

  &:after {
    background-image: url(${Light});
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    bottom: 0;
    content: '';
    opacity: 0;
    padding-bottom: 20%;
    left: 0;
    position: absolute;
    right: 0;
    transform: translateY(50%);
    transition: opacity 0.7s ${easings.out.cubic};
    z-index: -1;
  }

  &:hover {
    &:after {
      opacity: 0.3;
    }
    ${OverlayNavEpisodeButton} {
      opacity: 1;
    }
  }
`
