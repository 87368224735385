import { colors } from '@styles/vars/colors.style'
import styled from 'styled-components'

export const PlayButtonMain = styled.div`
  background-color: ${colors.offWhite};
  color: ${colors.dark};
  border-radius: 3.5rem;
  display: flex;
  height: 7rem;
  position: relative;
  width: 16.8rem;
  z-index: 2;
`

export const PlayButtonIcon = styled.div`
  align-items: center;
  background-color: ${colors.light};
  border-radius: 50%;
  display: flex;
  height: 6.6rem;
  justify-content: center;
  margin-left: 0.2rem;
  margin-top: 0.2rem;
  min-width: 6.6rem;
  width: 6.6rem;

  svg {
    height: 2rem;
    transform: translateX(0.2rem);
    width: 1.7rem;
  }
`

export const PlayButtonText = styled.div`
  align-items: center;
  display: flex;
  padding: 0 1.2rem;
`
