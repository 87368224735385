import React from 'react'

const Logo = ({
  width = 284,
  height = 19,
  fill = 'currentColor',
  responsive = true,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 284 19"
      aria-hidden="true"
      focusable="false"
      {...svgAttributes}
    >
      <path d="M39.839.646h-2.193v17.913h7.53v-2.046H39.84V.646Z" fill={fill} />
      <path
        d="M10.555 7.87H2.192V.646H0v17.913h2.192v-8.6h8.363v8.6h2.193V.646h-2.193V7.87Z"
        fill={fill}
      />
      <path
        d="M63.017 8.294 58.706.646h-2.517l5.731 10.119v7.794h2.193v-7.794L69.843.646h-2.516l-4.31 7.648Z"
        fill={fill}
      />
      <path
        d="M106.384.264a9.163 9.163 0 0 0-6.479 2.745A9.523 9.523 0 0 0 97.18 9.6c0 2.489.97 4.876 2.696 6.635a9.116 9.116 0 0 0 6.508 2.749 9.116 9.116 0 0 0 6.509-2.749 9.478 9.478 0 0 0 2.696-6.635 9.518 9.518 0 0 0-2.726-6.59 9.161 9.161 0 0 0-6.479-2.746Zm0 16.584a6.94 6.94 0 0 1-2.699-.565 7.042 7.042 0 0 1-2.281-1.576 7.214 7.214 0 0 1-1.515-2.347 7.314 7.314 0 0 1-.517-2.76 7.278 7.278 0 0 1 .482-2.793c.341-.889.854-1.7 1.508-2.383a7.015 7.015 0 0 1 2.298-1.598 6.901 6.901 0 0 1 5.453 0 7.02 7.02 0 0 1 2.299 1.598 7.185 7.185 0 0 1 1.508 2.383c.342.89.505 1.839.481 2.793a7.295 7.295 0 0 1-.519 2.76 7.216 7.216 0 0 1-1.515 2.347 7.057 7.057 0 0 1-2.283 1.576 6.938 6.938 0 0 1-2.7.565Z"
        fill={fill}
      />
      <path
        d="M50.838.646h-2.193v17.913h7.53v-2.046h-5.337V.646Z"
        fill={fill}
      />
      <path
        d="M89.344 13.476 84.381.264l-4.967 13.212L74.497.646h-2.373l7.29 18.625L84.38 6.135l4.963 13.136L96.64.646H94.26l-4.917 12.83Z"
        fill={fill}
      />
      <path
        d="M25.44.264a9.163 9.163 0 0 0-6.48 2.745A9.522 9.522 0 0 0 16.236 9.6c0 2.489.97 4.876 2.696 6.635a9.117 9.117 0 0 0 6.509 2.749 9.116 9.116 0 0 0 6.508-2.749A9.477 9.477 0 0 0 34.644 9.6a9.523 9.523 0 0 0-2.725-6.59 9.163 9.163 0 0 0-6.48-2.746Zm0 16.584a6.943 6.943 0 0 1-2.698-.568 7.053 7.053 0 0 1-2.278-1.576 7.208 7.208 0 0 1-1.514-2.346 7.319 7.319 0 0 1-.518-2.758c0-1.897.74-3.716 2.055-5.058a6.95 6.95 0 0 1 4.962-2.095 6.95 6.95 0 0 1 4.962 2.095A7.224 7.224 0 0 1 32.466 9.6a7.318 7.318 0 0 1-.52 2.762 7.211 7.211 0 0 1-1.518 2.348 7.053 7.053 0 0 1-2.285 1.576 6.942 6.942 0 0 1-2.703.562Z"
        fill={fill}
      />
      <path
        d="M255.683 2.593a6.521 6.521 0 0 0-3.674 1.137 6.72 6.72 0 0 0-2.437 3.026 6.872 6.872 0 0 0-.376 3.896 6.782 6.782 0 0 0 1.81 3.452 6.578 6.578 0 0 0 3.387 1.846 6.494 6.494 0 0 0 3.821-.384 6.644 6.644 0 0 0 2.969-2.484 6.835 6.835 0 0 0 1.115-3.746 6.808 6.808 0 0 0-1.938-4.768 6.549 6.549 0 0 0-4.677-1.975Zm0 11.836a4.926 4.926 0 0 1-2.775-.858 5.069 5.069 0 0 1-1.84-2.286 5.183 5.183 0 0 1-.284-2.942 5.123 5.123 0 0 1 1.367-2.608 4.969 4.969 0 0 1 2.558-1.393 4.906 4.906 0 0 1 2.886.29 5.017 5.017 0 0 1 2.242 1.875 5.163 5.163 0 0 1-.624 6.428 4.958 4.958 0 0 1-3.53 1.494Z"
        fill={fill}
      />
      <path
        d="M241.081 18.493h4.209V.811h-4.209v17.682ZM242.7 2.461h.971v14.382h-.971V2.461Z"
        fill={fill}
      />
      <path
        d="M127.712.264a9.163 9.163 0 0 0-6.479 2.745 9.522 9.522 0 0 0-2.725 6.591 9.47 9.47 0 0 0 2.768 6.502 9.12 9.12 0 0 0 6.438 2.676 9.12 9.12 0 0 0 6.439-2.676 9.474 9.474 0 0 0 2.768-6.502 9.522 9.522 0 0 0-2.727-6.592 9.163 9.163 0 0 0-6.482-2.744Zm0 16.584a6.987 6.987 0 0 1-4.965-2.15 7.262 7.262 0 0 1-2.024-5.098 7.291 7.291 0 0 1 .481-2.793c.342-.889.855-1.7 1.509-2.383a7.005 7.005 0 0 1 2.298-1.598 6.901 6.901 0 0 1 5.453 0 7.005 7.005 0 0 1 2.298 1.598 7.172 7.172 0 0 1 1.509 2.383c.341.89.505 1.839.481 2.793a7.303 7.303 0 0 1-.521 2.766 7.203 7.203 0 0 1-1.522 2.35 7.048 7.048 0 0 1-2.29 1.574 6.938 6.938 0 0 1-2.707.558Z"
        fill={fill}
      />
      <path
        d="M274.828 0a9.033 9.033 0 0 0-5.084 1.58 9.295 9.295 0 0 0-3.367 4.193 9.497 9.497 0 0 0-.516 5.393 9.39 9.39 0 0 0 2.51 4.777 9.103 9.103 0 0 0 4.689 2.551 8.998 8.998 0 0 0 5.29-.534 9.196 9.196 0 0 0 4.107-3.438 9.496 9.496 0 0 0 .845-8.762 9.363 9.363 0 0 0-1.989-3.03 9.147 9.147 0 0 0-2.976-2.023A9.007 9.007 0 0 0 274.828 0Zm0 17.022a7.439 7.439 0 0 1-4.189-1.295 7.658 7.658 0 0 1-2.777-3.45 7.83 7.83 0 0 1-.429-4.44 7.73 7.73 0 0 1 2.064-3.935 7.492 7.492 0 0 1 3.86-2.104 7.406 7.406 0 0 1 4.356.437 7.575 7.575 0 0 1 3.384 2.831 7.796 7.796 0 0 1-.941 9.702 7.474 7.474 0 0 1-5.328 2.254Z"
        fill={fill}
      />
      <path
        d="M226.909.646h-2.193v17.913h7.53v-2.046h-5.337V.646Z"
        fill={fill}
      />
      <path
        d="M274.828 2.593a6.527 6.527 0 0 0-3.675 1.137 6.717 6.717 0 0 0-2.436 3.026 6.855 6.855 0 0 0-.376 3.896 6.782 6.782 0 0 0 1.81 3.452 6.575 6.575 0 0 0 3.386 1.846 6.498 6.498 0 0 0 3.822-.384 6.636 6.636 0 0 0 2.968-2.484 6.835 6.835 0 0 0 1.115-3.746 6.811 6.811 0 0 0-1.937-4.768 6.552 6.552 0 0 0-4.677-1.975Zm0 11.836a4.929 4.929 0 0 1-2.772-.865 5.076 5.076 0 0 1-1.833-2.289 5.187 5.187 0 0 1-.279-2.94 5.122 5.122 0 0 1 1.37-2.605 4.968 4.968 0 0 1 2.558-1.39 4.909 4.909 0 0 1 2.885.293 5.014 5.014 0 0 1 2.239 1.876c.548.837.841 1.82.841 2.827a5.148 5.148 0 0 1-1.471 3.604 4.955 4.955 0 0 1-3.538 1.489Z"
        fill={fill}
      />
      <path
        d="M255.683 0a9.035 9.035 0 0 0-5.088 1.573 9.3 9.3 0 0 0-3.373 4.19 9.51 9.51 0 0 0-.521 5.395 9.397 9.397 0 0 0 2.506 4.78 9.107 9.107 0 0 0 4.69 2.555 8.996 8.996 0 0 0 5.291-.531 9.2 9.2 0 0 0 4.11-3.439 9.47 9.47 0 0 0 1.544-5.187c0-2.476-.965-4.85-2.683-6.601A9.07 9.07 0 0 0 255.683 0Zm0 17.022a7.433 7.433 0 0 1-4.188-1.295 7.651 7.651 0 0 1-2.777-3.45 7.821 7.821 0 0 1-.429-4.44 7.736 7.736 0 0 1 2.063-3.935 7.496 7.496 0 0 1 3.86-2.104 7.405 7.405 0 0 1 4.356.437 7.57 7.57 0 0 1 3.384 2.831 7.797 7.797 0 0 1 1.271 4.27 7.776 7.776 0 0 1-2.211 5.432 7.478 7.478 0 0 1-5.329 2.254Z"
        fill={fill}
      />
      <path
        d="M143.85.646h-3.635v17.918h3.566c5.55 0 9.926-2.947 9.926-9.053-.023-5.965-4.496-8.865-9.857-8.865Zm-.698 15.858h-.773V2.688h.773c4.472 0 8.325 1.688 8.325 6.912 0 5.225-3.853 6.904-8.325 6.904Z"
        fill={fill}
      />
      <path
        d="M171.769 9.006V8.96c1.189-.57 1.887-2.089 1.887-3.395 0-4.041-3.122-4.918-6.434-4.918h-2.141v17.918h4.103c3.099 0 6.105-1.415 6.105-5.012.014-2.098-1.448-4.164-3.52-4.546Zm-4.496-6.365h.629c2.049 0 3.728.429 3.728 2.947 0 2.518-1.702 2.994-3.779 2.994h-.555l-.023-5.941Zm1.675 13.924h-1.675v-6.191h1.351c1.956 0 4.473.518 4.473 3.041 0 2.523-2.119 3.15-4.149 3.15Z"
        fill={fill}
      />
      <path
        d="M214.244 13.476 209.281.264l-4.963 13.212-4.917-12.83h-2.377l7.294 18.625 4.963-13.136 4.963 13.136L221.538.646h-2.377l-4.917 12.83Z"
        fill={fill}
      />
      <path
        d="M186.802.264a9.161 9.161 0 0 0-6.467 2.752 9.518 9.518 0 0 0-2.719 6.584c0 2.489.969 4.876 2.696 6.635a9.115 9.115 0 0 0 6.508 2.749 9.117 9.117 0 0 0 6.509-2.749 9.478 9.478 0 0 0 2.696-6.635 9.526 9.526 0 0 0-2.732-6.597 9.165 9.165 0 0 0-6.491-2.739Zm0 16.584a6.938 6.938 0 0 1-2.7-.565 7.043 7.043 0 0 1-2.282-1.576 7.202 7.202 0 0 1-1.516-2.347 7.295 7.295 0 0 1-.519-2.76 7.291 7.291 0 0 1 .481-2.793c.342-.889.855-1.7 1.509-2.383a7.005 7.005 0 0 1 2.298-1.598 6.9 6.9 0 0 1 5.453 0 7.005 7.005 0 0 1 2.298 1.598 7.172 7.172 0 0 1 1.509 2.383c.341.89.505 1.839.481 2.793a7.332 7.332 0 0 1-.517 2.76 7.201 7.201 0 0 1-1.515 2.347 7.042 7.042 0 0 1-2.281 1.576 6.94 6.94 0 0 1-2.699.565Z"
        fill={fill}
      />
    </svg>
  )
}

export default Logo
