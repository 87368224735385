import { animation } from '@styles/vars/animation.style'
import { easings } from '@styles/vars/easings.style'
import styled from 'styled-components'

export const AnimateImageMain = styled.div`
  ${props =>
    props.filled &&
    `
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  `}
`

export const AnimateImageMask = styled.div.attrs(props => ({
  style: {
    transitionDelay: `${
      props.inView ? props.delay : animation.maskShowDuration
    }s`,
  },
}))`
  border-radius: 10px;
  height: ${props => (props.filled ? `100%` : `auto`)};
  overflow: hidden;
  opacity: ${props => (props.inView ? 1 : 0)};
  transform: ${props => (props.inView ? 'translateY(0)' : 'translateY(3rem)')};
  transition: opacity 1.25s ${easings.out.cubic},
    transform 1.25s ${easings.out.cubic};
`

export const AnimateImageScale = styled.div.attrs(props => ({
  style: {
    transitionDelay: `${
      props.inView ? props.delay : animation.maskShowDuration
    }s`,
  },
}))`
  height: ${props => (props.filled ? `100%` : `auto`)};
  transform: ${props =>
    props.inView
      ? `scale(${props.endScale})`
      : `scale(${props.endScale + 0.25})`};
  transition: transform 1.5s ${easings.out.cubic};

  > div {
    height: 100%;
    width: 100%;
  }
`
