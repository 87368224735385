import React, { useState, useEffect } from 'react'
import { useLocation } from '@reach/router'
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import {
  CookieBarBtn,
  CookieBarMain,
  CookieBarWrapper,
  CookieBarText,
} from './index.style'

const CookieBar = () => {
  const gtmKey = 'gatsby-gdpr-google-tagmanager'
  // const fbPixelKey = 'gatsby-gdpr-facebook-pixel'
  const location = useLocation()
  const [show, setShow] = useState(false)

  const enableCookies = () => {
    document.cookie = `${gtmKey}=true;path=/`
    initializeAndTrack(location)
    setShow(false)
  }

  useEffect(() => {
    // const cookie = document.cookie

    // if (cookie.length === 0) {
    //   setShow(true)
    // } else {
    //   let accepted = cookie
    //     .split('; ')
    //     .find(row => row.startsWith(`${gtmKey}=`))

    //   if (accepted) {
    //     accepted = accepted.split('=')[1]
    //   }

    //   if (accepted === 'true') {
    //     setShow(false)
    //   } else {
    //     setShow(true)
    //   }
    // }

    enableCookies()
  }, [enableCookies])

  return (
    <CookieBarMain show={show} aria-hidden={!show}>
      <CookieBarWrapper>
        <CookieBarText>
          By using this website, you agree to our use of cookies
        </CookieBarText>

        <CookieBarBtn onClick={enableCookies}>Accept</CookieBarBtn>
      </CookieBarWrapper>
    </CookieBarMain>
  )
}

export default CookieBar
