import React, { forwardRef } from 'react'
import {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  TextBody,
  TextBodySmall,
  Caption,
  CaptionSmall,
  Cta,
  CtaSmall,
} from '@styles/vars/textStyles.style'

const ThisHeading1 = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <Heading1 ref={ref} as={as} color={color} maxWidth={maxWidth} {...props}>
      {children}
    </Heading1>
  )
)

const ThisHeading2 = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <Heading2 ref={ref} as={as} color={color} maxWidth={maxWidth} {...props}>
      {children}
    </Heading2>
  )
)

const ThisHeading3 = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <Heading3 ref={ref} as={as} color={color} maxWidth={maxWidth} {...props}>
      {children}
    </Heading3>
  )
)

const ThisHeading4 = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <Heading4 ref={ref} as={as} color={color} maxWidth={maxWidth} {...props}>
      {children}
    </Heading4>
  )
)

const ThisHeading5 = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <Heading5 ref={ref} as={as} color={color} maxWidth={maxWidth} {...props}>
      {children}
    </Heading5>
  )
)

const ThisTextBody = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <TextBody ref={ref} as={as} color={color} maxWidth={maxWidth} {...props}>
      {children}
    </TextBody>
  )
)

const ThisTextBodySmall = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <TextBodySmall
      ref={ref}
      as={as}
      color={color}
      maxWidth={maxWidth}
      {...props}
    >
      {children}
    </TextBodySmall>
  )
)

const ThisCaption = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <Caption ref={ref} as={as} color={color} maxWidth={maxWidth} {...props}>
      {children}
    </Caption>
  )
)

const ThisCaptionSmall = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <CaptionSmall
      ref={ref}
      as={as}
      color={color}
      maxWidth={maxWidth}
      {...props}
    >
      {children}
    </CaptionSmall>
  )
)

const ThisCta = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <Cta ref={ref} as={as} color={color} maxWidth={maxWidth} {...props}>
      {children}
    </Cta>
  )
)

const ThisCtaSmall = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <CtaSmall ref={ref} as={as} color={color} maxWidth={maxWidth} {...props}>
      {children}
    </CtaSmall>
  )
)

export {
  ThisHeading1 as Heading1,
  ThisHeading2 as Heading2,
  ThisHeading3 as Heading3,
  ThisHeading4 as Heading4,
  ThisHeading5 as Heading5,
  ThisTextBody as TextBody,
  ThisTextBodySmall as TextBodySmall,
  ThisCaption as Caption,
  ThisCaptionSmall as CaptionSmall,
  ThisCta as Cta,
  ThisCtaSmall as CtaSmall,
}
