import React, { useRef, useEffect, useState } from 'react'
import gsap from 'gsap'
import { hideOverlayNav, hideIntroMask, useStore } from '@Store'
import {
  IntroMaskWrapper,
  IntroMaskBlock,
  IntroMaskContent,
  IntroMaskCount,
  IntroMaskCountNumber,
  IntroMaskLogo,
} from './index.style'
import { animation } from '@styles/vars/animation.style'
import { Heading4, TextBodySmall } from '@components/TextStyles'
import Kaiser from '@svgs/Kaiser'
import { colors } from '@styles/vars/colors.style'
import Spacer from '@components/Spacer'
import { disablePageScroll } from '@utils'
import { enablePageScroll } from '@utils'
import { localise } from '@utils/localise'

const IntroMask = () => {
  const [store, dispatch] = useStore()
  const { smoothScroll } = store

  const [count, setCount] = useState(0)
  const [countString, setCountString] = useState('000')

  const maskRef = useRef()
  const contentRef = useRef()
  const countRef = useRef()
  const smoothScrollRef = useRef()

  useEffect(() => {
    smoothScrollRef.current = smoothScroll
  }, [smoothScroll])

  useEffect(() => {
    const tl = gsap.timeline()

    if (store.showIntroMask) {
      const mask = maskRef.current
      const content = contentRef.current
      const count = {
        el: countRef.current,
        value: 0,
      }

      tl.set(mask, { transformOrigin: '50% 100%' })
        .to(mask, {
          scaleY: 1,
          duration: animation.maskShowDuration,
          ease: 'power3.inOut',
          onStart: () => {
            disablePageScroll()
            hideOverlayNav(dispatch)
          },
          onComplete: () => {
            if (smoothScrollRef.current) {
              smoothScrollRef.current.scrollTop(0)
            }
          },
        })
        .to(content, {
          opacity: 1,
          duration: 4,
          ease: 'power3.inOut',
        })
        .to(
          count,
          {
            value: 100,
            duration: 4,
            onUpdate: () => {
              let currentCount = count.value > 99.7 ? 100 : count.value,
                valuePrefix = '',
                valueString = Math.floor(currentCount).toString()

              if (valueString.length === 1) {
                valuePrefix = '00'
              } else if (valueString.length === 2) {
                valuePrefix = '0'
              }

              setCount(currentCount)
              setCountString(valuePrefix + valueString)
            },
            ease: 'power3.inOut',
          },
          '-=3'
        )
        .to(content, {
          opacity: 0,
          duration: 1,
          ease: 'power3.inOut',
        })
        .set(mask, { transformOrigin: '50% 0%' })
        .to(mask, {
          scaleY: 0,
          duration: animation.maskHideDuration,
          ease: 'power3.inOut',
        })
        .add(() => {
          enablePageScroll()
          hideIntroMask(dispatch)
        }, '-=.8')
    }

    // return () => {
    //   if (tl) {
    //     tl.kill()
    //   }
    // }
  }, [store.showIntroMask, dispatch])

  return (
    <IntroMaskWrapper show={store.showIntroMask}>
      <IntroMaskBlock ref={maskRef} />
      <IntroMaskContent ref={contentRef}>
        <Heading4>
          {localise('The First')}{' '}
          <IntroMaskCount>
            <IntroMaskCountNumber visible={count >= 99.3}>
              {countString[0]}
            </IntroMaskCountNumber>
            <IntroMaskCountNumber visible={count >= 10}>
              {countString[1]}
            </IntroMaskCountNumber>
            <IntroMaskCountNumber visible={count >= 1}>
              {countString[2]}
            </IntroMaskCountNumber>
          </IntroMaskCount>{' '}
          {localise('Years')}
        </Heading4>
        <Spacer size={[10, 10]} />
        <TextBodySmall>{localise('Presented by')}</TextBodySmall>
        <Spacer size={[50, 50]} />
        <IntroMaskLogo>
          <Kaiser fill={colors.skyblue} />
        </IntroMaskLogo>
      </IntroMaskContent>
    </IntroMaskWrapper>
  )
}

export default IntroMask
