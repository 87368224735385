import React from 'react'
import { PlayButtonMain, PlayButtonIcon, PlayButtonText } from './index.style'
import { CaptionSmall } from '@components/TextStyles'
import Play from '@svgs/Play'

const PlayButton = ({ label }) => {
  return (
    <PlayButtonMain>
      <PlayButtonIcon>
        <Play />
      </PlayButtonIcon>
      <PlayButtonText>
        <CaptionSmall>{label}</CaptionSmall>
      </PlayButtonText>
    </PlayButtonMain>
  )
}

export default PlayButton
