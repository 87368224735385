import React from 'react'
import PropTypes from 'prop-types'
import { useInView } from 'react-intersection-observer'
import {
  AnimateImageMain,
  AnimateImageMask,
  AnimateImageScale,
} from './index.style'
import { useStore } from '@Store/'
import { animation } from '@styles/vars/animation.style'

const AnimateImage = ({
  children,
  delay = 0,
  parallax = 0.9,
  triggerOnce = true,
  rootMargin,
  animate = true,
  fill = false,
}) => {
  const [ref, inView] = useInView({
    triggerOnce,
    rootMargin: rootMargin ? rootMargin : animation.rootMargin,
  })
  const [store] = useStore()
  const { showIntroMask, showPageMask } = store

  return (
    <AnimateImageMain ref={ref} filled={fill}>
      <AnimateImageMask
        inView={inView && animate && !showIntroMask && !showPageMask}
        delay={delay}
        filled={fill}
      >
        <AnimateImageScale
          inView={inView && animate && !showIntroMask && !showPageMask}
          delay={delay}
          endScale={2 - parallax + (parallax === 1 ? 0 : 0.2)}
          filled={fill}
        >
          <div data-speed={parallax}>{children}</div>
        </AnimateImageScale>
      </AnimateImageMask>
    </AnimateImageMain>
  )
}

AnimateImage.propTypes = {
  children: PropTypes.node,
  delay: PropTypes.number,
  parallax: PropTypes.number,
  triggerOnce: PropTypes.bool,
  rootMargin: PropTypes.string,
  animate: PropTypes.bool,
  fill: PropTypes.bool,
}

export default AnimateImage
