export const colors = {
  dark: '#0E0F0E',
  light: '#fff',
  grey: '#F0F1F2',
  offWhite: '#ECEBEA',
  pink2: '#EE787B',
  pink3: '#EA545B',
  purple3: '#2B2172',
  skyblue: '#009EE3',
  darkblue: '#1A1350',
  blueGrey3: '#1E2F4C',
  midgrey: '#939392',
}
