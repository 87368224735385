import React, { useEffect } from 'react'
import PageTransitionLink from '@components/PageTransitionLink'
import Container from '@components/Container'
import MenuToggle from '@components/MenuToggle'
import {
  HeaderPreLogo,
  HeaderContainer,
  HeaderLogo,
  HeaderMain,
  HeaderInner,
} from './index.style'
import Logo from '@svgs/Logo'
import { colors } from '@styles/vars/colors.style'
import OverlayNav from '@components/OverlayNav'
import { useStore } from '@Store/'
import { setHeaderLight } from '@Store/'
import gsap from 'gsap'
import { animation } from '@styles/vars/animation.style'
import { setHeaderDark } from '@Store/'
import AnimateFadeIn from '@components/animation/AnimateFadeIn'

const Header = () => {
  const [store, dispatch] = useStore()
  const { headerLight } = store

  useEffect(() => {
    if (store.overlayNavIsOpen) {
      gsap.delayedCall(animation.maskShowDuration, () =>
        setHeaderLight(dispatch)
      )
    } else {
      gsap.delayedCall(animation.maskShowDuration, () =>
        setHeaderDark(dispatch)
      )
    }
  }, [store.overlayNavIsOpen, dispatch])

  return (
    <HeaderContainer>
      <HeaderMain>
        <HeaderPreLogo
          onClick={() => {}}
          id={`pre-logo`}
          aria-label={`Top of page`}
        />
        <Container>
          <HeaderInner>
            <AnimateFadeIn>
              <HeaderLogo>
                <PageTransitionLink to="/">
                  <Logo fill={headerLight ? colors.light : colors.dark} />
                </PageTransitionLink>
              </HeaderLogo>
            </AnimateFadeIn>

            <AnimateFadeIn>
              <MenuToggle fill={headerLight ? colors.light : colors.dark} />
            </AnimateFadeIn>
          </HeaderInner>
        </Container>
      </HeaderMain>

      <OverlayNav />
    </HeaderContainer>
  )
}

export default Header
