import React from 'react'

const IconArrowRight = ({
  width = 14,
  height = 12,
  fill = 'currentColor',
  responsive = true,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 12"
      aria-hidden="true"
      focusable="false"
      {...svgAttributes}
    >
      <path
        d="M8.01.31 6.597 1.724l4.243 4.243-4.243 4.243 1.415 1.414 5.656-5.657L8.011.31Z"
        fill={fill}
      />
      <path d="M11.303 5.017v2H0v-2h11.303Z" fill={fill} />
    </svg>
  )
}

export default IconArrowRight
