import styled from 'styled-components'
import { animation } from '@styles/vars/animation.style'
import { easings } from '@styles/vars/easings.style'

export const SplitTextWrapper = styled.span`
  display: block;

  .split__mask {
  }

  .split__text {
    display: inline-block;
    will-change: transform;
    opacity: ${props => (props.show ? 1 : 0)};
    transform: ${props => (props.show ? 'translateY(0)' : 'translateY(3rem)')};
    transition: transform 1.5s ${easings.out.cubic},
      opacity 1.25s ${easings.out.cubic};
    transition-delay: ${props => {
      if (props.show) {
        const animationDelay =
          props.type.indexOf('chars') > -1
            ? animation.charDuration
            : props.type.indexOf('words') > -1
            ? animation.wordDuration
            : animation.lineDuration
        return `calc(${props.delay}s + ${animationDelay} * var(--splitTextDelay))`
      } else {
        return `${animation.maskShowDuration}s`
      }
    }};
  }
`
