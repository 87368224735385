import styled from 'styled-components'
import { clamp, setVh } from '@styles/utils/conversion.style'
import { colors } from '@styles/vars/colors.style'
import { zIndex } from '@styles/vars/zIndex.style'
import { Heading4 } from '@styles/vars/textStyles.style'
import { easings } from '@styles/vars/easings.style'

export const IntroMaskWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: ${props => (props.show ? 'auto' : 'none')};
  z-index: ${zIndex.pageTransitionMask};
`

export const IntroMaskBlock = styled.div`
  position: relative;
  width: 100%;
  ${setVh('height', 100)}
  z-index: 1;
  background-color: ${colors.purple3};
`

export const IntroMaskContent = styled.div`
  color: ${colors.light};
  left: 50%;
  opacity: 0;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 2;

  ${Heading4} {
    ${clamp('font-size', 28, 36)};
  }
`

export const IntroMaskCount = styled.span`
  display: inline-block;
`

export const IntroMaskCountNumber = styled.span`
  display: inline-block;
  opacity: ${props => (props.visible ? 1 : 0.2)};
  transition: opacity 3s ${easings.out.cubic};
  ${clamp('width', 17, 22)};
`

export const IntroMaskLogo = styled.div`
  display: flex;
  justify-content: center;
`
