export const font = {
  primary: {
    family: 'futura-pt',
    weight: {
      regular: 400,
      medium: 500,
    },
  },
  secondary: {
    family: 'Apercu LA Phil',
    weight: {
      regular: 400,
      medium: 500,
    },
  },
  fallback: {
    family: 'sans-serif',
  },
}
