import styled, { css } from 'styled-components'
import { font } from '@styles/vars/font.style'
import { colors } from '@styles/vars/colors.style'
import { clamp } from '@styles/utils/conversion.style'
import { easings } from '@styles/vars/easings.style'

const minFontSize = 16
const maxFontSize = 18

export const ButtonMain = styled.button`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.01em;
  cursor: pointer;
  font-family: ${font.secondary.family};
  font-weight: ${font.secondary.weight.medium};
  ${clamp('font-size', minFontSize, maxFontSize)}
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transform: ${props => props.flip && `rotateZ(180deg)`};

  > * {
    transition: opacity 0.5s ${easings.inOut.default};
  }

  &[disabled] {
    > * {
      opacity: 0.2;
    }
  }

  ${props =>
    props.hoverShift &&
    `
    :not(:disabled):hover {
      ${ButtonContent} {
        transform: translateX(-0.4em);
        transition-property: transform;
        transition-duration: 0.5s;
        transition-timing-function: ${easings.inOut.default};
      }

      ${ButtonText} + ${ButtonIcon} {
        opacity: 1;
        transform: translateY(-50%) translateX(100%) translateX(.5em);
        transition: transform .5s ${easings.inOut.default}, opacity .5s ${easings.inOut.default};
      }
    }
  `}

  ${({ variant }) => {
    if (variant === 'primary') {
      return ButtonPrimary
    }

    if (variant === 'secondary') {
      return ButtonSecondary
    }
  }}
`

export const ButtonContent = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition-property: transform, opacity;
  transition-duration: 0.5s;
  transition-timing-function: ${easings.inOut.default};

  * + * {
    margin-left: 0.3em;
  }
`

export const ButtonText = styled.span``

export const ButtonIcon = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 1em;

  ${props =>
    props.hoverShift &&
    `
    ${ButtonText} + & {
      margin-top: -0.05em;
      opacity: 0;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%) translateX(100%);
      transition: transform 0s .3s, opacity .3s ${easings.inOut.default};
      width: 0.8em;
    }
  `}

  svg {
    width: 100%;
  }
`

const ButtonPrimary = css`
  color: ${props => (props.theme === 'solid' ? colors.dark : colors.light)};
  background-color: ${props =>
    props.theme === 'solid' ? colors.light : 'transparent'};
  border: 0.1rem solid ${colors.light};
  border-radius: ${100 / maxFontSize}em;
  padding: ${13 / maxFontSize}em ${36 / maxFontSize}em;
  transition-property: background-color, color;
  transition-duration: 0.2s;
  transition-timing-function: ${easings.inOut.default};

  ${props =>
    props.iconOnly &&
    `
    padding: 0;
    ${clamp('height', 44, 54)};
    ${clamp('width', 44, 54)};

    ${ButtonIcon} + ${ButtonIcon} {
      opacity: 0;
      position: absolute;
      right: 0;
      transform: translateX(-50%);
    }

    :not(:disabled):hover {
      ${ButtonIcon}{
        opacity: 0;
        transform: translateX(50%);
        transition: opacity .5s ${easings.inOut.default}, transform .5s ${
      easings.inOut.default
    };
      }

      ${ButtonIcon} + ${ButtonIcon} {
        opacity: 1;
        transform: translateX(0%);
        transition: opacity .5s ${easings.out.default}, transform .5s ${
      easings.out.default
    };
    transition-delay: .2s;
      }
    }
  `}

  :not(:disabled):hover {
  }
`

const ButtonSecondary = css`
  color: ${colors.dark};
  background-color: transparent;
  border: none;
  transition-property: border-color, color;
  transition-duration: 0.2s;
  transition-timing-function: ${easings.inOut.default};

  &:before {
    background: ${colors.dark};
    content: '';
    height: 0.1rem;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    transition-property: right;
    transition-duration: 0.5s;
    transition-timing-function: ${easings.inOut.default};
  }

  ${props =>
    props.hoverShift &&
    `
    :not(:disabled):hover {
      ${ButtonContent} {
        transform: translateX(0);
      }
      &:before {
        right: -1.3em;
      }
    }
  `}

  :not(:disabled):hover {
  }
`
